import {useContext, useEffect, useState} from 'react';
import {ConfirmDialogService, ToastService, IamColumn, useDataTable, useDataTableBasic, useDialogCrup} from '@iamsoftware/react-hooks';
import {TcgpConnectedServices as Service} from './TcgpConnectedServices';
import {CommonService} from '../../service/CommonService';

import {Button} from 'primereact/button'
import {AppContext} from '../../layout/context/appcontext';

export const InvoiceConnected = () =>{
  const header = 'Danh sách kết nối TCGP';
  const dataKey = 'remoteAccountId';

  const {me} = useContext(AppContext);

  const [items, setItems] = useState([])
  const [invoiceProvider, setInvoiceProvider] = useState([])
  const [invoiceSaleType, setInvoiceSaleType] = useState([])
  const [deleteInvoiceTemplatePartyIds, setDeleteInvoiceTemplatePartyIds] = useState([])

  useEffect(() => {
    CommonService.getInvoiceProvider().then(({items}) =>{
      setInvoiceProvider(items.map(item =>{
        return {value: item.fromPartyId, label: item.fromPartyName}
      }))
    })
    CommonService.getInvoiceFormTypesSales().then(({listData}) =>{
      setInvoiceSaleType(listData.map(item =>{
        return {value: item.enumId, label: item.description}
      }))
    })
  }, []);

  const onEditorChange = (rowData, column, newValue) => {
    setItems(prevItems => prevItems.map(prevItem => {
      if (prevItem._id === rowData._id) {
        return {
          ...prevItem,
          [column.field]: newValue
        }
      }
      return prevItem;
    }));
  }

  const addBlankItem = () => {
    setItems(prevOrderItems => {
      const _id = `${Date.now()}_${Math.random()}`;
      prevOrderItems.push({
        _id,
        actions: [
          {icon: 'pi pi-trash', className: 'p-button-danger', title: 'Xoá mã hàng', command: () => doRowDelete(_id)}
        ]
      });
      return [...prevOrderItems];
    });
  }

  //them id cho items
  useEffect(() => {
    if (items && items?.length > 0){
      items.map(item =>{
        if (!item._id){
          const _id = `${Date.now()}_${Math.random()}`;
          item._id = _id;
          item.actions = [
            {icon: 'pi pi-trash', className: 'p-button-danger', title: 'Xoá mã hàng', command: () => doRowDelete(_id)}
          ]
        }
        return item
      })
    }
  }, [items]);

  const tableHeader = (
    <div className="flex align-items-center justify-content-between">
      <span>{header}</span>
      <div className="flex align-items-center">
        <Button label="Thêm" title="Thêm mới" size="small" className="mr-2 mb-1 h-2rem" icon="pi pi-plus" severity="success" onClick={() => doCreate()}></Button>
      </div>
    </div>
  )

  const {render: renderDataTable, reloadLazyData} = useDataTable({
    tableHeader: tableHeader,
    dataKey,
    stateKey: 'mainTable',
    columns: [
      {field: 'integratePseudoId', header: 'MST TCGP', width: 150},
      {field: 'integratePartyName', header: 'Tên TCGP', minWidth: 200},
      {field: 'fromDate', header: 'Ngày kết nối', width: 150, dataType: 'date'},
      {field: 'status', header: 'Trạng thái', minWidth: 150},
      {field: 'username', header: 'Tên truy cập', minWidth: 200},
      {field:'webAddress', header:'Link phần mềm', minWidth: 200},
    ],
    getList(lazyLoadEvent: any): Promise<any> {
      return Service.getList(lazyLoadEvent)
    },
    actionColumnWidth:90,
    initActions(items: Array<any>) {
      if (items){
        items.forEach(item =>{
          item.actions = [
            {icon: 'pi pi-pencil', title: 'Chỉnh sửa', className: 'p-button-warning', command: () => doUpdate(item.remoteAccountId)},
            {icon: 'pi pi-key', className: 'p-button-secondary', title: 'Đổi mật khẩu', command: () => doUpdatePassword(item.remoteAccountId)}
          ]
        })
      }
    }
  })

  //header cua invoice type
  const headerInvoiceType = (
    <div className="flex align-items-center justify-content-between">
      <span>Mẫu hóa đơn sử dụng</span>
      <div className="flex align-items-center">
        <Button title="Thêm dòng" size="small" className="mr-2" rounded outlined icon="pi pi-plus" severity="info" onClick={() => addBlankItem()}></Button>
      </div>
    </div>
  )

  const {render: renderDataTableBasic} = useDataTableBasic({
    tableHeader: headerInvoiceType,
    dataKey: '_id',
    indexColumnWidth: 40,
    columns: [
      {
        field: 'invoiceFormTypeEnumId', header: 'Mẫu số', width: 200, dataType:'editor',editorConfig: {
          onEditorChange, field: {type:'Dropdown', DropdownProps: {options: invoiceSaleType}}
        }},
      {field:'invoiceSerial',header:'Ký hiệu', width: 150, dataType:'editor', editorConfig:{
          onEditorChange
        }}
    ],
    actionColumnWidth: 40,
    items
  })

  const {render: renderCrupDialog, create, update, form} = useDialogCrup({
    header:'Kết nối TCGP',
    dataKey,
    width:'45rem',
    fields:[
      {field:'integratePartyId', header:'TCGP', className:'md:col-12', type:'Dropdown', DropdownProps:{options: invoiceProvider}},
      {field:'partyTaxIdCreate', header:'MST', className:'md:col-6', disabled: true, display: 'Create'},
      {field:'partyTaxIdUpdate', header:'MST', className:'md:col-12', disabled: true, display: 'Update'},
      {field:'password', header:'Mật khẩu', className:'md:col-6', display:'Create', type:'Password'},
      {type:'custom', body: renderDataTableBasic()}
    ],
    async createItem(item: any): Promise<any> {
      if (inject(item).invoiceTemplatePartyItems.filter(it => it.invoiceFormTypeEnumId && it.invoiceSerial).length <= 0) {
        ToastService.error('Mẫu hóa đơn không được rỗng')
        return Promise.reject()
      } else {
        const data_2 = await Service.create(inject(item));
        if (data_2.success === 'N') {
          ToastService.error(`${data_2.errorMessage}`);
          return Promise.reject()
        }
      }
    },
    updateItem(id: string, item: any): Promise<any> {
      if (inject(item).invoiceTemplatePartyItems.filter(it => it.invoiceFormTypeEnumId && it.invoiceSerial).length <= 0){
        ToastService.error('Mẫu hóa đơn không được rỗng')
        return Promise.reject()
      }else {
        return Service.update(id, inject(item))
      }
    },
    reloadLazyData
  })

  const {render: renderDialogUpdatePassword, update: updatePassWord} = useDialogCrup({
    header:'!Cập nhật mật khẩu TCGP',
    dataKey,
    width: '30rem',
    fields:[
      {field:'partyTaxId', header:'MST', className:'md:col-12', disabled: true},
      {field:'password', header:'Mật khẩu', className:'md:col-12', type:'Password', required: true},
    ],
    updateItem(id: string, item: any): Promise<any> {
      return Service.updatePassword(id,item)
    },
    reloadLazyData
  })




  const inject = (data) =>{
    data.invoiceTemplatePartyItems = items
    data.deleteInvoiceTemplatePartyIds = deleteInvoiceTemplatePartyIds
    return data
  }

  const doCreate = () =>{
    setDeleteInvoiceTemplatePartyIds([])
    setItems([])
    create()
    form.setValue({partyTaxIdCreate: me.pseudoId})
  }

  const doUpdate = (id: any) =>{
    setDeleteInvoiceTemplatePartyIds([])
    if (id){
      Service.get(id).then(data =>{
        setItems(data.invoiceTemplatePartyItems)
        data.partyTaxIdUpdate = me.pseudoId
        update(data)
      })
    }
  }

  const doUpdatePassword = (id: any) =>{
    if (id){
      updatePassWord({partyTaxId: me.pseudoId, remoteAccountId: id})
    }
  }


  const doRowDelete = (id: any) =>{
    const invoiceTemplateId = items.find(it => it._id === id)
    ConfirmDialogService.confirm('Xóa mẫu hóa đơn','Bạn xác nhận muốn xóa mẫu hóa đơn này?', ()=>{
      if (invoiceTemplateId?.invoiceTemplatePartyId) {
        deleteInvoiceTemplatePartyIds.push(invoiceTemplateId?.invoiceTemplatePartyId)
      }
      setItems(prevItems => {
        return prevItems.filter(prevItem => prevItem._id !== id);
      });
    })
  }

  return (
    <div className="grid">
      <div className="col-12">
        <div className="col-12 pb-0">
          {renderDataTable()}
        </div>
      </div>
      {renderCrupDialog()}
      {renderDialogUpdatePassword()}
    </div>
  )
}