import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {TabView, TabPanel} from 'primereact/tabview';
import {Checkbox} from "primereact/checkbox";
import {Dropdown} from 'primereact/dropdown';
import {InputNumber} from 'primereact/inputnumber';
import {AutoComplete} from "primereact/autocomplete";
import {RadioButton} from "primereact/radiobutton";
import {InputText} from 'primereact/inputtext';

import {FormatDisplay, ToastService, useDataTableBasic, useForm} from '@iamsoftware/react-hooks';
import {Fieldset} from 'primereact/fieldset';
import {useCrupSOItems} from './CrupSOItems';
import {CustomersService} from '../khach-hang/CustomersService';
import {CommonService} from '../../../service/CommonService';
import {SalesOrdersService as Service} from './ordersService';
import {FacilitiesService} from '../../kho-van/kho-hang/FacilitiesService';
import {EmployeesService} from '../../quan-ly/nhan-vien/EmployeesService';
import {BankAccountService} from '../../danh-muc/tai-khoan-ngan-hang/BankAccountService';
import {useCrupReadOnlySOItems} from './CrupReadOnlySOItems';
import {useCrupCustomer} from '../khach-hang/CrupCustomer';
import {useCrupEmployee} from '../../quan-ly/nhan-vien/CrupEmployee';
import {useCrupItemsByGdt} from './CrupItemsByGdt';
import {SaleInvoices} from "../hoa-don";


export const useCrupSO = ({reloadLazyData}, doClosePopUp?) => {
  const [isUpdate, setIsUpdate] = useState(false)
  const [orderId, setOrderId] = useState(null);
  const [display, setDisplay] = useState(null);
  const [paymentExisted, setPaymentExisted] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [paymentInstruments, setPaymentInstruments] = useState([]);
  const [paymentInstrument, setPaymentInstrument] = useState('');
  const [invoiceFormTypes, setInvoiceFormTypes] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [bankAccounts, setBankAccounts] = useState([]);
  const [invoicePaymentMethods, setInvoicePaymentMethods] = useState([]);
  const [invoiceCheck, setInvoiceCheck] = useState(false)
  const [shipmentCheck, setShipmentCheck] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0)
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalExemptAmount, setTotalExemptAmount] = useState(0);
  const [customerPartyName, setCustomerPartyName] = useState(null);
  const [displayCreateCustomer, setDisplayDocreateCustomer] = useState(null);
  const [employee, setEmployee] = useState(null)
  const [displayCrupEmployee, setDisplayCrupEmployee] = useState(null)
  const [editableForm, setEditableForm] = useState(false)
  const [onCredit, setOnCredit] = useState('');
  const [displayId, setDisplayId] = useState('')
  const [paymentRefNum, setPaymentRefNum] = useState('')
  const [shipmentOriginId, setShipmentOriginId] = useState('')
  //xem chi tiet phieu thu tu ban hang qua man hinh phieu thu

  //thong tin kenh ban hang
  const [expenseItems, setExpenseItems] = useState(null);
  const [expenseTotal, setExpenseTotal] = useState(0);

  //Tạo dơn hàng từ tct
  const [createByGdt, setCreateByGdt] = useState(false);
  const [createFromGdt, setCreateFromGdt] = useState(false);

  //xuất hóa đơn từ hệ thống TCGP
  const [createInvoiceToIntegParty, setCreateInvoiceToIntegParty] = useState(false)
  const [invoiceProviders, setInvoiceProviders] = useState(null);

  //reload dsach hoa don khi cap nhat don hang
  const {reloadSaleInvoice} = SaleInvoices()

  const itemTemplate = (item) => {
    return (
      <div className="flex align-items-center justify-content-between">
        <div style={{width:'30%'}}>{item.label}</div>
        <div style={{width:'70%', float:'left'}}>{item.partyName}</div>
      </div>
    );
  };
  const searchCustomer = event => {
    CustomersService.find('Buyer', event.query).then(data => {
      setCustomers(data.listData.map(item => {
        delete item.disabled;
        return {...item, value: item.partyId, label: item.pseudoId};
      }));
    });
  }

  useEffect(() => {
    if (display) {
      CustomersService.getList('{"filters":{"disabled":{"value":"N","matchMode":"equals"}}}').then(data => {
        setCustomers(data.listData.map(item => {
          return {value: item.partyId, label: item.partyName}
        }));
      });
      CommonService.getPaymentInstruments().then(data => {
        setPaymentInstruments(data.listData.map(item => {
          return {value: item.enumId, label: item.description}
        }));
      });
      CommonService.getInvoicePaymentMethod().then(data => {
        setInvoicePaymentMethods(data.listData.map(item => {
          return {value: item.enumId, label: item.description}
        }));
      });

      BankAccountService.getList('').then(data => {
        setBankAccounts(data.listData.map(item => {
          item.toPaymentMethodId = item.paymentMethodId
          return {value: item.toPaymentMethodId, label: `${item.bankName} - ${item.bankAccount}`}
        }));
      });
      FacilitiesService.getList('').then(data => {
        setFacilities(data.listData.map(item => {
          return {value: item.facilityId, label: item.facilityName}
        }));
      });
      CommonService.getInvoiceFormTypesSales().then(data => {
        setInvoiceFormTypes(data.listData.map(item => {
          return {value: item.enumId, label: `${item.enumCode} - ${item.description}`}
        }));
      });
      CommonService.getInvoiceProvider().then(({items}) =>{
        setInvoiceProviders(items.map(item =>{
          return {value: item.fromPartyId, label: item.fromPartyName}
        }))
      });
    }
  }, [display])


  const searchEmployees = event => {
    EmployeesService.find(event.query).then(data => {
      setEmployees(data.listData.map(item => {
        delete item.disabled;
        let label = `[${item.pseudoId}] - ${item.partyName}`;
        return {...item, value: item.partyId, label: label};
      }));
    });
  }

  const getDisplayId = () => {
    Service.genPseudoId().then(data => {
      setDisplayId(data.code)
    })
  }
  const getPaymentRefNum = () => {
    Service.genPaymentPseudoId(paymentInstrument).then(data => {
      setPaymentRefNum(data.code)
    })
  }
  const getShipmentPseudoId = () => {
    Service.genShipmentPseudoId().then(data => {
      setShipmentOriginId(data.code)
    })
  }

  //set edit enable from
  const changeCustomer = (data) => {
    setCustomerPartyName(data);
    setEditableForm(true);
  }

  const {renderProducts, items, setItems, deleteOrderItemSeqIds, itemsDirty, setItemsDirty} = useCrupSOItems({display, disabled: disabled});
  const {renderProducts2, setItems2} = useCrupReadOnlySOItems({display, disabled: disabled});
  const {renderCustomer, doCreateCustomer} = useCrupCustomer({display: displayCreateCustomer, setDisplay: setDisplayDocreateCustomer, reloadLazyData})
  const {doCreateEmployee, renderEmployee} = useCrupEmployee({display: displayCrupEmployee, setDisplay: setDisplayCrupEmployee, reloadLazyData});
  const {setItemsGdt, itemsGdt, dirtyGdt, renderProductsGdt} = useCrupItemsByGdt({display})
  const commonInfoForm = useForm({
    fields: [
      {
        field: 'customerParty', type: 'custom', className: 'md:col-4',required: true, body: <div>
          <div>
            <div>
              Mã khách hàng<span style={{color: 'red'}}>*</span>
            </div>
            <div className='p-inputgroup mt-2'>
              <AutoComplete field='label' disabled={disabled} value={customerPartyName} suggestions={customers} completeMethod={searchCustomer} itemTemplate={itemTemplate} onChange={(e) => {
                changeCustomer(e.value);
              }} dropdown/>
              {!disabled && <span className="p-inputgroup-addon" style={{background: '#22C55E', color: 'white', cursor: 'pointer'}} onClick={() => doCreateCustomer()}>
            <i className="pi pi-plus"/>
          </span>}
            </div>
          </div>
        </div>, display: createByGdt === false
      },
      {field: 'customerName', header: 'Tên khách hàng', className: 'md:col-8', disabled: true, display: createByGdt === false},
      {field: 'address1', header: 'Địa chỉ', className: 'md:col-12', display: createByGdt === false},
      {
        field: 'toPartyId', header: 'Mã khách hàng', className: 'md:col-4', required: true, display: createByGdt === true
      },
      {
        field: 'customerPartyName', header: 'Tên khách hàng', className: 'md:col-8', required: true, display: createByGdt === true
      },
      {
        field: 'toPartyTaxId', header: 'Mã số thuế', className: 'md:col-4', displayDependency(): boolean {
          return (activeIndex === 0 || activeIndex === 1) && createByGdt === false;
        }
      },
      {
        field: 'customerPartyTaxId', header: 'Mã số thuế', className: 'md:col-4', display: createByGdt === true, disabled: createByGdt
      },
      {
        field: 'partyContactName', header: 'Tên người liên hệ', className: 'md:col-4', displayDependency(): boolean {
          return activeIndex === 0
        }
      },
      {
        field: 'enteredByParty', type: 'custom', className: 'md:col-4', body: <div>
          <div>
            <div>
              Nhân viên thực hiện
            </div>
            <div className='p-inputgroup mt-2'>
              <AutoComplete field='label' disabled={disabled} value={employee} suggestions={employees} completeMethod={searchEmployees} itemTemplate={itemTemplate} onChange={(e) => {
                setEmployee(e.value);
              }} dropdown/>
              {!disabled && <span className="p-inputgroup-addon" style={{background: '#22C55E', color: 'white', cursor: 'pointer'}} onClick={() => doCreateEmployee()}>
            <i className="pi pi-plus"/>
          </span>}
            </div>
          </div>
        </div>, displayDependency(item: any): boolean {
          return activeIndex === 0 || activeIndex === 2 || activeIndex === 3
        }
      },

      {
        field: 'sourceAccountNumber', header: 'Số tài khoản ', className: 'md:col-4', displayDependency(): boolean {
          return activeIndex === 1
        }
      },
      {
        field: 'sourceBankName', header: 'Tên NH', className: 'md:col-4', displayDependency(): boolean {
          return activeIndex === 1
        }
      },
      {
        field: 'facilityId', header: 'Kho hàng', className: 'md:col-4', type: 'Dropdown', required: true, DropdownProps: {
          options: facilities
        }, displayDependency(): boolean {
          return activeIndex === 2
        }
      },
      {
        field: 'shipmentDeliveryName', header: 'Người nhận', className: 'md:col-4', displayDependency(): boolean {
          return activeIndex === 2
        }
      },
      {
        field: 'toPaymentMethodId', header: 'Tài khoản nhận', type: 'Dropdown', DropdownProps: {options: bankAccounts}, className: 'md:col-4', displayDependency(): boolean {
          return activeIndex === 3 && paymentInstrument === 'PiWireTransfer'
        }, disabled: paymentInstrument == 'PiCash'
      },
      {
        field: 'paymentFromName', header: 'Người nộp', className: 'md:col-4', displayDependency(): boolean {
          return activeIndex === 3 && paymentInstrument === 'PiCash'
        }
      },
      {
        field: 'paymentComments', header: 'Chứng từ kèm theo', className: 'md:col-4', displayDependency(): boolean {
          return activeIndex === 3
        }
      },


      {
        field: 'paymentMemo', header: 'Diễn giải', className: 'md:col-12', displayDependency(): boolean {
          return activeIndex === 3
        }
      },
      {
        field: 'orderComments', header: 'Diễn giải', className: 'md:col-12', displayDependency(): boolean {
          return activeIndex === 0 || activeIndex === 1
        }
      },

      {
        field: 'invoiceDescription', header: 'Ghi chú', className: 'md:col-12', displayDependency(): boolean {
          return activeIndex === 1
        }
      },
      {
        field: 'shipmentComment', header: 'Diễn giải', className: 'md:col-9', displayDependency(): boolean {
          return activeIndex === 2
        }
      },
      {
        field: 'shipmentHandlingInstructions', header: 'Kèm theo', className: 'md:col-3', displayDependency(): boolean {
          return activeIndex === 2
        }
      },

      //kenh ban hang
      {
        field: 'salesChannelEnum', header: 'Kênh bán hàng', className: 'md:col-4', disabled: true, displayDependency(item: any): boolean {
          return activeIndex === 0 && expenseItems?.length > 0
        }
      },
      {
        field: 'storeName', header: 'Gian hàng', className: 'md:col-8', disabled: true, displayDependency(item: any): boolean {
          return activeIndex === 0 && expenseItems?.length > 0
        }
      }
    ],
    disabled
  });
  const infoForm = useForm({
    fields: [
      {
        type: 'custom',display: activeIndex === 1 && !createFromGdt, body:
          <div className="">
            <Checkbox disabled={disabled} className=" mt-2 mr-1" onChange={e => setCreateInvoiceToIntegParty(e.checked)} checked={createInvoiceToIntegParty}></Checkbox>
            <label onClick={() => {
              doClickLabel('invoiceProvider')
            }} style={{fontSize: '1rem'}}>Xuất hóa đơn trên hệ thống TCGP</label>
          </div>
      },
      {field: 'invoiceProviderPartyId', required: true, className: 'md:col-12', header: 'TCGP', type: 'Dropdown', DropdownProps: {options: invoiceProviders}, display: createInvoiceToIntegParty === true && activeIndex === 1},
      {
        field: 'displayId', type: 'custom', className: 'md:col-12', body: <div>
          <div>
            <div>
              Số chứng từ<span style={{color: 'red'}}>*</span>
            </div>
            <div className='p-inputgroup mt-2'>
              <InputText value={displayId} onChange={e => setDisplayId(e.target.value)}/>
              <span className="p-inputgroup-addon" style={{background: '#3B82F6', color: 'white', cursor: 'pointer'}} onClick={getDisplayId}>
            <i className="pi pi-sync"/>
          </span>
            </div>
          </div>
        </div>, displayDependency(): boolean {
          return activeIndex === 0
        },
      },
      {
        field: 'placedDate', header: 'Ngày chứng từ', InputTextProps: {type: 'date'}, className: 'md:col-12', displayDependency(): boolean {
          return activeIndex === 0
        }
      },

      {
        field: 'invoiceFormTypeEnumId', header: 'Mẫu hóa đơn', className: 'md:col-12', type: 'Dropdown', DropdownProps: {
          options: invoiceFormTypes
        }, displayDependency(): boolean {
          return activeIndex === 1
        }, required: true, disabled: createByGdt
      },
      {
        field: 'invoiceSerial', header: 'Kí hiệu', className: 'md:col-6', displayDependency(): boolean {
          return activeIndex === 1
        }, required: true, disabled: createByGdt || createInvoiceToIntegParty
      },
      {
        field: 'invoiceNo', header: 'Số hóa đơn', className: 'md:col-6', displayDependency(): boolean {
          return activeIndex === 1
        }, disabled: createByGdt || createInvoiceToIntegParty
      },
      {
        field: 'invoiceDate', header: 'Ngày hóa đơn', className: 'md:col-6', InputTextProps: {type: 'date'}, displayDependency(): boolean {
          return activeIndex === 1
        }, disabled: createByGdt
      },
      {
        field: 'shipmentOriginId', type: 'custom', className: 'md:col-12', body: <div>
          <div>
            <div>
              Mã chứng từ<span style={{color: 'red'}}>*</span>
            </div>
            <div className='p-inputgroup mt-2'>
              <InputText value={shipmentOriginId} onChange={e => setShipmentOriginId(e.target.value)}/>
              <span className="p-inputgroup-addon" style={{background: '#3B82F6', color: 'white', cursor: 'pointer'}} onClick={getShipmentPseudoId}>
            <i className="pi pi-sync"/>
          </span>
            </div>
          </div>
        </div>, displayDependency(): boolean {
          return activeIndex === 2
        },
      },
      {
        field: 'estimatedShipDate', header: 'Ngày giao', className: 'md:col-12', InputTextProps: {type: 'date'}, displayDependency(): boolean {
          return activeIndex === 2
        }
      },
      {
        field: 'invoicePaymentMethodEnumId', header: 'HTTT', className: 'md:col-6', type: 'Dropdown', DropdownProps: {
          options: invoicePaymentMethods
        }, displayDependency(): boolean {
          return activeIndex === 1
        }
      },
      {
        field: 'externalId', header: ' Mã CQT', className: 'md:col-12',
        displayDependency(): boolean {
          return activeIndex === 1
        }, disabled: createInvoiceToIntegParty
      },
      {field:'issueStatus', header:'TT phát hành', className:'md:col-12', disabled: true, display: createInvoiceToIntegParty && activeIndex === 1}
    ],
    disabled
  });

  const {invoiceProviderPartyId, invoiceFormTypeEnumId} = infoForm.getRawValue()
  //set lai dropdown mau hoa don khi thay doi checkbox xuat hoa don TCGP
  useEffect(() => {
    if (createInvoiceToIntegParty === true && invoiceProviderPartyId){
      if (infoForm.dirty['invoiceProviderPartyId']){
        const _value = infoForm.getRawValue();
        _value.issueStatus = 'Chưa xử lý';
        infoForm.setValue(_value);
      }

      CommonService.getInvoiceTemplateParty(invoiceProviderPartyId).then(({items}) =>{
        setInvoiceFormTypes(items.map(item =>{
          return {value: `${item.invoiceTemplatePartyId}_${item.invoiceFormTypeEnumId}_${item.invoiceSerial}`, label: `${item.invoiceForm} - ${item.invoiceSerial}`}
        }))
      })
    }else if (createInvoiceToIntegParty === false){
      CommonService.getInvoiceFormTypesSales().then(data => {
        setInvoiceFormTypes(data.listData.map(item => {
          return {value: item.enumId, label: `${item.enumCode} - ${item.description}`}
        }));
      });
    }
  }, [createInvoiceToIntegParty, invoiceProviderPartyId]);

  //fill Ki hieu khi chon mau hoa don
  useEffect(() => {
    if (invoiceFormTypeEnumId && createInvoiceToIntegParty && infoForm.dirty['invoiceFormTypeEnumId']){
      const _value = infoForm.getRawValue();
      _value.invoiceSerial = invoiceFormTypeEnumId.split('_')[2];
      infoForm.setValue(_value)
    }
  }, [invoiceFormTypeEnumId]);

  const paymentForm = useForm({
    fields: [
      {
        field: 'paymentRefNum', type: 'custom', className: 'md:col-12', body: <div>
          <div>
            <div>
              Số chứng từ<span style={{color: 'red'}}>*</span>
            </div>
            <div className='p-inputgroup mt-2'>
              <InputText value={paymentRefNum} onChange={e => setPaymentRefNum(e.target.value)}/>
              <span className="p-inputgroup-addon" style={{background: '#3B82F6', color: 'white', cursor: 'pointer'}} onClick={getPaymentRefNum}>
            <i className="pi pi-sync"/>
          </span>
            </div>
          </div>
        </div>, displayDependency(): boolean {
          return activeIndex === 3
        },
      },
      {
        field: 'effectiveDate', header: 'Ngày  chứng từ', InputTextProps: {type: 'date'}, className: 'md:col-12', displayDependency(): boolean {
          return activeIndex === 3
        }, required: true
      },
      {
        field: 'grandTotal', header: 'Số tiền thanh toán', type: 'InputNumber', className: 'md:col-12', displayDependency(): boolean {
          return activeIndex === 3
        }, disabled: true
      },


    ],
    disabled
  });

  const form1 = useForm({
    fields: [
      {
        type: 'custom', className: 'md:col-9',
        body: <Fieldset legend="Thông tin chung">
          {commonInfoForm.render()}
        </Fieldset>
      },
      {
        type: 'custom', className: 'md:col-3 fieldset-width-invoice',
        body: <Fieldset legend="Chứng từ">
          {infoForm.render()}
        </Fieldset>
      },
    ],
    disabled
  });
  const form2 = useForm({
    fields: [
      {
        type: 'custom', className: 'md:col-9',
        body: <Fieldset legend="Thông tin chung">
          {commonInfoForm.render()}
        </Fieldset>
      },
      {
        type: 'custom', className: 'md:col-3',
        body: <Fieldset legend="Chứng từ">
          {paymentForm.render()}
        </Fieldset>
      },
    ],
    disabled
  });

  const {render: renderExpenseDataTable} = useDataTableBasic({
    tableHeader: 'Thông tin chi phí',
    dataKey: 'orderItemSeqId',
    columns: [
      {field: 'itemDescription', header: 'Loại chi phí', minWidth: 200},
      {
        field: '', header: 'Áp dụng cho', dataType: 'custom', customCell(rowData: any): any {
          return <span>[{rowData.otherPartyProductId}] {rowData.comments}</span>
        }
      },
      {field: 'unitAmount', header: 'Số tiền', width: 150, dataType: 'number'},
    ]
    ,
    items: expenseItems
  })

  const commonData = commonInfoForm.getRawValue();
  const infoData = infoForm.getRawValue();
  const paymentData = paymentForm.getRawValue();
  useEffect(() => {
    if (itemsDirty && createByGdt === false) {
      setTotalAmount(0);
      setTotalExemptAmount(0);
      setGrandTotal(0)
      let totalCounter = 0;
      let exemptCounter = 0;
      let grandTotalCounter = 0;
      items.forEach(item => {
        if (item.productId) {
          totalCounter += item.itemTotal
          if (item.exemptAmount) {
            exemptCounter += item.exemptAmount
          }
        }
      });
      grandTotalCounter = totalCounter - exemptCounter + expenseTotal
      setTotalAmount(totalCounter);
      setTotalExemptAmount(exemptCounter);
      setGrandTotal(grandTotalCounter);
    }
  }, [JSON.stringify(items)]);

  useEffect(() => {
    if (onCredit === 'N') {
      setPaymentInstrument('PiWireTransfer')
    }
  }, [onCredit])
  //Fill tổng tiền vào tiền thanh toán nếu payment chưa được tạo trên hệ thống
  useEffect(() => {
      if (onCredit === 'N' && paymentExisted === false && paymentInstrument) {
        const _value = paymentForm.getRawValue();
        const _valueOrder = infoForm.getRawValue();
        Service.genPaymentPseudoId(paymentInstrument).then(data => {
          setPaymentRefNum(data.code);
          if (!_value.effectiveDate){
            _value.effectiveDate = _valueOrder.placedDate;
          }
          _value.grandTotal = grandTotal;
          paymentForm.setValue(_value);
        })
        setActiveIndex(3)
      } else if (onCredit === 'Y') {
        setActiveIndex(0)
      }
    }
    ,
    [onCredit, paymentInstrument]
  );

  useEffect(() => {
    if (shipmentCheck === true && !shipmentOriginId) {
      Service.genShipmentPseudoId().then(data => {
        setShipmentOriginId(data.code)
      })
    }
  }, [shipmentCheck]);


  useEffect(() => {
    if (customerPartyName?.value && editableForm === true) {
      CustomersService.get(customerPartyName.value).then(data => {
        commonData.address1 = data.address1;
        commonData.partyContactName = data.relatedPartyName;
        commonData.toPartyTaxId = data.partyTaxId;
        commonData.sourceAccountNumber = data.accountNumber;
        commonData.sourceBankName = data.bankName;
        commonData.orderComments = `Bán hàng cho ${data.partyName}`;
        commonData.customerName = data.partyName;
        commonInfoForm.setValue(commonData);
      })
    }
  }, [customerPartyName]);

  const reset = () => {
    setEditableForm(false)
    setCustomerPartyName(null)
    commonInfoForm.reset();
    infoForm.reset();
    paymentForm.reset();
  }

  const inject = () => {
    const data = Object.assign(
      commonData,
      infoData,
      paymentData);
    if (createByGdt === false) {
      data.items = items.filter(it => it.productId)
      data.customerPartyId = customerPartyName?.value
    } else {
      data.invoiceSyncId = itemsGdt[0]?.invoiceId
      data.items = itemsGdt;
      data.toPartyName = data.customerPartyName;
      data.toPseudoId = data.toPseudoId;
      data.toPartyTaxId = data.customerPartyTaxId;
    }

    //xuat hoa don TCGP
    if (createInvoiceToIntegParty){
      data.createInvoiceToIntegParty = 'Y'
      data.invoiceFormTypeEnumId = invoiceFormTypeEnumId.split('_')[1];
      data.invoiceTemplatePartyId = invoiceFormTypeEnumId.split('_')[0];
    }else {
      data.createInvoiceToIntegParty = 'N'
    }

    if (invoiceCheck === true) {
      data.createInvoice = "Y";
    } else {
      data.createInvoice = "N";
    }
    if (shipmentCheck === true) {
      data.createShipment = "Y";
    } else {
      data.createShipment = "N";
    }
    if (onCredit) {
      data.onCredit = onCredit
    }
    if (employee) {
      data.enteredByPartyId = employee?.value;
    }
    if (deleteOrderItemSeqIds?.length) {
      data.deleteOrderItemSeqIds = deleteOrderItemSeqIds;
    }
    if (data.invoiceDate) {
      data.invoiceDate = Date.parse(data.invoiceDate)
    }
    if (data.estimatedShipDate) {
      data.estimatedShipDate = Date.parse(data.estimatedShipDate)
    }
    if (data.placedDate) {
      data.placedDate = Date.parse(data.placedDate)
    }
    if (data.effectiveDate) {
      data.effectiveDate = Date.parse(data.effectiveDate)
    }
    if (paymentInstrument) {
      data.paymentInstrumentEnumId = paymentInstrument
    }
    if (displayId) {
      data.displayId = displayId
    }
    if (shipmentOriginId) {
      data.shipmentOriginId = shipmentOriginId
    }
    if (paymentRefNum) {
      data.paymentRefNum = paymentRefNum
    }
    return data
  }

  const doCreate = (defaultValue?: any) => {
    setCreateInvoiceToIntegParty(false)
    setCreateByGdt(false)
    setExpenseItems(null)
    setExpenseTotal(0)
    setItemsDirty(false);
    setPaymentExisted(false);
    setOrderId(null);
    setEmployee(null)
    reset();
    setActiveIndex(0);
    setGrandTotal(0);
    setTotalAmount(0);
    setTotalExemptAmount(0);
    setOnCredit('Y')
    setInvoiceCheck(true)
    setShipmentCheck(true)
    Service.genPseudoId().then(data => {
      setDisplayId(data.code)
      infoForm.setValue(defaultValue?.formvalue || {
        placedDate: FormatDisplay.date(new Date(), 'YYYY-MM-DD'),
        invoiceDate: FormatDisplay.date(new Date(), 'YYYY-MM-DD'),
        estimatedShipDate: FormatDisplay.date(new Date(), 'YYYY-MM-DD'),
      })
    })
    setItems([]);
    setItems2([]);
    setDisabled(false);
    setDisplay(Date.now());
    setEditableForm(false)
  }
  const doUpdate = async (orderId: string, data: any, doView: boolean, lookBy?: string) => {
    setCreateByGdt(false)
    setExpenseTotal(0)
    setExpenseTotal(0)
    setItemsDirty(false);
    setPaymentExisted(false);
    reset()
    setDisplayId(data.displayId)
    setShipmentOriginId(data.shipmentOriginId)
    setPaymentRefNum(data.paymentRefNum)
    setOrderId(orderId);
    setTotalAmount(0);
    setTotalExemptAmount(0);
    setOnCredit(data.onCredit)
    setPaymentInstrument(data.paymentInstrumentEnumId)
    if (data.placedDate) {
      data.placedDate = FormatDisplay.date(new Date(data.placedDate), 'YYYY-MM-DD');
    }
    if (data.estimatedShipDate) {
      data.estimatedShipDate = FormatDisplay.date(new Date(data.estimatedShipDate), 'YYYY-MM-DD');
    }
    if (data.invoiceDate) {
      data.invoiceDate = FormatDisplay.date(new Date(data.invoiceDate), 'YYYY-MM-DD');
    }

    if (data.effectiveDate) {
      data.effectiveDate = FormatDisplay.date(new Date(data.effectiveDate), 'YYYY-MM-DD');
    }
    if (data.createInvoice === "Y") {
      data.createInvoice = true;
      setInvoiceCheck(true)
    } else {
      data.createInvoice = false;
      setInvoiceCheck(false)
    }
    if (data.createShipment === "Y") {
      data.createShipment = true;
      setShipmentCheck(true)
    } else {
      data.createShipment = false;
      setShipmentCheck(false)
    }
    if (data.customerPartyId) {
      await CustomersService.get(data.customerPartyId).then(item => {
        data.address1 = item.address1;
      })
      setCustomerPartyName({value: data.customerPartyId, label: data.customerPseudoId})
      data.customerName = data.customerPartyName;
    }
    paymentForm.setValue(data);
    if (data.paymentStatusId === 'PmntDelivered') {
      setPaymentExisted(true)
    }
    if (data.createFromGdt === 'Y'){
      setCreateFromGdt(true)
    }else {
      setCreateFromGdt(false)
    }
    if (data.enteredByPartyId) {
      await EmployeesService.get(data.enteredByPartyId).then(item => {
        if (!Object.hasOwn(item, 'messages')) {
          setEmployee({value: item.partyId, label: `[${item.pseudoId}] - ${item.partyName}`})
        } else {
          setEmployee(null);
        }
      });
    }
    //Xu ly gan expenseItems
    if (data.expenseItems) {
      setExpenseItems(data.expenseItems)
    }
    if (data.expenseTotal) {
      setExpenseTotal(data.expenseTotal)
    }
    //Gan tao hoa don tcgp
    if (data.createInvoiceToIntegParty === 'Y'){
      setCreateInvoiceToIntegParty(true)
      if (!data.issueStatus){
        data.issueStatus = 'Chờ xử lý';
      }
      data.invoiceFormTypeEnumId = `${data.invoiceTemplatePartyId}_${data.invoiceFormTypeEnumId}_${data.invoiceSerial}`
    }else {
      setCreateInvoiceToIntegParty(false)
    }

    setGrandTotal(data.grandTotal);
    setTotalAmount(data.subTotal);
    setTotalExemptAmount(data.discountTotal);
    commonInfoForm.setValue(data);

    setTimeout(() => {
      infoForm.setValue(data);
    }, 100)
    //them dau vao product cho autocomplete
    data.items = data.items.map(item =>{
      item.product = {value: item.productId, label: `[${item.pseudoId}] - ${item.productName}`}
      return item
    })
    setItems(data.items);
    setItems2(data.items.filter(it => it.productId));
    setEditableForm(false)
    if (lookBy === 'Payment') {
      //Chuyen den man hinh phieu chi khi xem chi tiet tu ketoan-phieuchi
      setActiveIndex(3)
    } else if (lookBy === 'Shipment') {
      //Chuyen den man hinh phieu xuat khi xem chi tiet tu kho-phieuxuat
      setActiveIndex(2)
    } else {
      setActiveIndex(0);
    }
    setIsUpdate(true);
    setTimeout(() => {
      if (doView === true) {
        setDisabled(doView);
        setDisplay(Date.now());
      } else {
        setDisabled(doView);
        setDisplay(Date.now());
      }
    }, 75)
  }
//Create invoice by GDT
  const doCreateGdt = (data) => {
    setCreateFromGdt(false)
    setCreateByGdt(true)
    setExpenseItems(null)
    setExpenseTotal(0)
    setItemsDirty(false);
    setPaymentExisted(false);
    setOrderId(null);
    setEmployee(null)
    reset();
    setActiveIndex(0);
    setGrandTotal(0);
    setTotalAmount(0);
    setTotalExemptAmount(0);
    setOnCredit('Y')
    setInvoiceCheck(true)
    setShipmentCheck(false)
    data.customerPartyTaxId = data.toPartyTaxId;
    data.toPartyId = data.toPartyTaxId;
    data.customerPartyName = data.toPartyName;
    data.address1 = data.toAddress;
    data.invoiceDate = FormatDisplay.date(new Date(data.invoiceDate), 'YYYY-MM-DD');
    Service.genPseudoId().then(it => {
      setDisplayId(it.code)
      data.placedDate = FormatDisplay.date(new Date(), 'YYYY-MM-DD');
      data.estimatedShipDate = FormatDisplay.date(new Date(), 'YYYY-MM-DD');
    })
    setTimeout(() => {
      infoForm.setValue(data)
      commonInfoForm.setValue(data)
      paymentForm.setValue(data)
      setItemsGdt(data.items.map(it => {
        it._id = `${Date.now()}_${it.invoiceItemSeqId}_${Math.random()}`
        return it
      }))
      setItems(data.items)
    }, 250)
    setDisabled(false);
    setDisplay(data);
    setEditableForm(false)
  }

  const onSubmit = event => {
    if (!submitting && infoForm.valid() && commonInfoForm.valid() && paymentForm.valid()) {
      if (items.filter(it => it.productId)?.length > 0) {
        setSubmitting(true);
        let promise;
        //check valid kh, so chung tu
        if ((!customerPartyName && !createByGdt) || (!shipmentOriginId && shipmentCheck === true) || !displayId){
          if (!customerPartyName){
            ToastService.error('Trường khách hàng không được rỗng');
          }
          if (!shipmentOriginId && shipmentCheck === true){
            ToastService.error('Trường mã chứng từ không được rỗng');
          }
          if(!displayId){
            ToastService.error('Trường số chứng từ không được rỗng');
          }
          setSubmitting(false);
        }else {
          if (orderId) {
            promise = Service.update(orderId, inject());
          } else {
            if (createByGdt) {
              promise = Service.createGdt(inject());
            } else {
              promise = Service.create(inject());
            }
          }
          promise.then(response => {
            if (response.errors) {
              ToastService.error(response.errors)
            } else {
              doClosePopUp();
              setDisplay(null);
              reset();
              ToastService.success();
              reloadLazyData();
              reloadSaleInvoice();
            }
          }).finally(() => {
            setSubmitting(false);
          });
        }
      } else {
        ToastService.error('Chưa có sản phẩm trong đơn hàng hoặc sản phẩm không tồn tại trên hệ thống')
      }
    }
    event.preventDefault();
  }
  const doSetInvoice = (data) => {
    setInvoiceCheck(data);
    if (invoiceCheck === false) {
      setActiveIndex(1)
    } else {
      setActiveIndex(0);
    }
  }
  const doSetShipment = (data) => {
    setShipmentCheck(data);
    setActiveIndex(0);
    if (shipmentCheck === false) {
      setActiveIndex(2)
    } else {
      setActiveIndex(0);
    }
  }
  useEffect(() => {
    if (onCredit === 'N') {
      if (!paymentRefNum) {
        getPaymentRefNum()
      }
      setActiveIndex(3)
    }
  }, [onCredit])

  //Change checkbox by click label
  const doClickLabel = (type) => {
    if (type === 'invoice') {
      switch (invoiceCheck) {
        case true:
          doSetInvoice(false)
          break;
        case false:
          doSetInvoice(true);
          break;
      }
    } else if (type === 'shipment') {
      switch (shipmentCheck) {
        case true:
          doSetShipment(false);
          break;
        case false:
          doSetShipment(true);
          break;
      }
    } else if (type === 'invoiceProvider'){
      switch (createInvoiceToIntegParty) {
        case true:
          setCreateInvoiceToIntegParty(false);
          break;
        case false:
          setCreateInvoiceToIntegParty(true);
          break;
      }
    }
  }

  const footer = (
    <div className="flex" style={{justifyContent: 'end'}}>
      {!disabled && <Button label="Lưu" icon="pi pi-check" size="small" onClick={onSubmit} loading={submitting}/>}
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(null)}/>
    </div>
  );
  const renderItems = (
    <div className="col-12">
      {disabled == false && createByGdt === false && renderProducts()}
      {disabled == true && createByGdt === false && renderProducts2()}
      {disabled == false && createByGdt === true && renderProductsGdt()}
      {expenseItems?.length > 0 && <div className="flex justify-content-between mt-3">
				<div className="flex" style={{width: '70vw', height: '200px'}}>
          {renderExpenseDataTable()}
				</div>
				<div className="flex" style={{width: '30vw', flexDirection: 'column'}}>
					<div className="flex align-items-center mb-3 ml-4">
						<label htmlFor="total-amount" style={{fontSize: '1.1rem', width: '50%'}} className="font-bold block">Tổng tiền hàng: </label>
						<InputNumber inputId="total-amount" value={totalAmount} disabled/>
					</div>
					<div className="flex align-items-center mb-3 ml-4">
						<label htmlFor="total-exempt" style={{fontSize: '1.1rem', width: '50%'}} className="font-bold block">Tổng tiền chiết khấu: </label>
						<InputNumber inputId="total-exempt" value={totalExemptAmount} disabled/>
					</div>
					<div className="flex align-items-center mb-3 ml-4">
						<label htmlFor="total-expense" style={{fontSize: '1.1rem', width: '50%'}} className="font-bold block">Tổng phí phải trả: </label>
						<InputNumber inputId="total-expense" value={expenseTotal} disabled/>
					</div>
					<div className="flex align-items-center mb-3 ml-4">
						<label htmlFor="total" style={{fontSize: '1.1rem', width: '50%'}} className="font-bold block">Tổng tiền thanh toán: </label>
						<InputNumber inputId="total" value={grandTotal} disabled/>
					</div>
				</div>
			</div>}
      {expenseItems?.length === 0 && <div className="flex flex-row-reverse flex-wrap mt-3">
				<div className="ml-4">
					<label htmlFor="total" className="font-bold block mb-2">Tổng tiền thanh toán</label>
					<InputNumber inputId="total" value={grandTotal} disabled/>
				</div>
				<div className="ml-4">
					<label htmlFor="total-exempt" className="font-bold block mb-2">Tổng tiền chiết khấu</label>
					<InputNumber inputId="total-exempt" value={totalExemptAmount} disabled/>
				</div>
				<div>
					<label htmlFor="total-amount" className="font-bold block mb-2">Tổng tiền hàng</label>
					<InputNumber inputId="total-amount" value={totalAmount} disabled/>
				</div>
			</div>}

    </div>);
  const header = () => {
    return (
      <div className="flex flex-wrap">
        <div className="md:col-2">
          <h4><b> Đơn hàng bán</b></h4>
        </div>
        <div className="md:col-9 justify-content-begin flex-wrap flex">
          <div className="flex flex-wrap mt-2">
            <div className="flex font-medium">
              <RadioButton className={'ml-1'} inputId="credit1" name="pizza" value="Y" onChange={(e) => setOnCredit(e.value)} checked={onCredit === 'Y'}/>
              <label htmlFor="credit1" style={{fontSize: '1rem', marginTop: '4px'}} className="ml-2">Ghi nợ </label>
            </div>
            <div className="flex justify-content-center font-medium">
              <RadioButton className={'ml-1'} inputId="credit2" name="pizza" value="N" onChange={(e) => setOnCredit(e.value)} checked={onCredit === 'N'}/>
              <label htmlFor="credit2" style={{fontSize: '1rem', marginTop: '4px'}} className="ml-2">Thu tiền ngay</label>
            </div>
          </div>
          <div className="flex justify-content-center font-medium">
            <div className="ml-2">
              <Dropdown disabled={disabled || onCredit === 'Y'} placeholder="Hình thức thanh toán" className="ml-2 w-full md:w-14rem p-inputtext-sm" value={paymentInstrument} onChange={(e) => setPaymentInstrument(e.value)} options={paymentInstruments} optionLabel="label"></Dropdown>
            </div>
            <div>
              <Checkbox disabled={disabled} className="ml-2 mt-2 mr-1" onChange={e => doSetInvoice(e.checked)} checked={invoiceCheck}></Checkbox>
              <label onClick={() => doClickLabel('invoice')} style={{fontSize: '1rem'}}>Tạo hóa đơn </label>
            </div>
            <div className="ml-2">
              <Checkbox disabled={disabled} className="ml-2 mt-2 mr-1" onChange={e => doSetShipment(e.checked)} checked={shipmentCheck}></Checkbox>
              <label onClick={() => {
                doClickLabel('shipment')
              }} style={{fontSize: '1rem'}}>Tạo phiếu xuất </label>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const render = () => {
    return (
      <Fragment>
        <Dialog header={header} footer={footer} visible={!!display} maximized={true} draggable={false} onHide={() => setDisplay(null)}>

          <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
            <TabPanel header='Đơn hàng bán'>
              <div className="grid">
                <div className="col-12">
                  {form1.render()}
                </div>
                {renderItems}
              </div>
            </TabPanel>
            {invoiceCheck === true &&
							<TabPanel header='Hóa đơn'>
								<div className="grid">
									<div className="col-12">
                    {form1.render()}
									</div>
                  {renderItems}
								</div>
							</TabPanel>}
            {shipmentCheck === true &&
							<TabPanel header='Phiếu xuất kho'>
								<div className="grid">
									<div className="col-12">
                    {form1.render()}
									</div>
                  {renderItems}
								</div>
							</TabPanel>}
            {onCredit === 'N' &&
							<TabPanel header='Phiếu thu'>
								<div className="grid">
									<div className="col-12">
                    {form2.render()}
									</div>
                  {renderItems}
								</div>
							</TabPanel>}
          </TabView>
        </Dialog>
        {renderCustomer()}
        {renderEmployee()}
      </Fragment>
    );
  }

  return {renderCrupSO: render, doCreateSO: doCreate, doUpdateSO: doUpdate, doCreateGdt};
}
