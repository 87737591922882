import {forwardRef, useContext, useImperativeHandle, useRef} from 'react';
import {Link} from 'react-router-dom';

import {classNames} from 'primereact/utils';
import {StyleClass} from 'primereact/styleclass';
import {useDialogCrup} from '@iamsoftware/react-hooks';

import {AppTopbarRef} from 'src/types';
import {LayoutContext} from 'src/layout/context/layoutcontext';
import {AppContext} from '../layout/context/appcontext';

import {AuthService} from 'src/service/AuthService';

import {Households} from 'src/shared/components/layout/Households';
import {AppStorage} from '../service/UserStorage';

export const AppTopbar = forwardRef<AppTopbarRef, any>((props, ref) => {

  const {layoutState, onMenuToggle, showProfileSidebar} = useContext(LayoutContext);
  const {me, household, setHousehold} = useContext(AppContext);

  const menubuttonRef = useRef(null);
  const topbarmenuRef = useRef(null);
  const topbarmenubuttonRef = useRef(null);
  const accountRef = useRef(null);

  useImperativeHandle(ref, () => ({
    menubutton: menubuttonRef.current,
    topbarmenu: topbarmenuRef.current,
    topbarmenubutton: topbarmenubuttonRef.current
  }));

  const logout = () => {
    AuthService.logout().then(() => {
      window.location.hash = '/login';
    });
  }

  const {render: renderDialogChangePassword, create: changePassword} = useDialogCrup({
    header: '!Đổi mật khẩu',
    width: '40rem',
    fields: [
      {field: 'oldPassword', header: 'Mật khẩu cũ', required: true, type: 'Password', className: 'md:col-12'},
      {field: 'newPassword', header: 'Mật khẩu mới', required: true, type: 'Password', className: 'md:col-12'},
      {field: 'newPasswordVerify', header: 'Nhập lại mật khẩu mới', required: true, type: 'Password', className: 'md:col-12'}
    ],
    createItem: item => {
      return AuthService.changePassword(item);
    },
    checkResponseError: data => {
      let error = '';
      if (data?.isSuccess === 'N') {
        error = data.messages;
      }
      return error;
    },
    useSaveSplitButton: false
  });

  return (
    <div className="layout-topbar">
      <Link to="/dashboard" className="layout-topbar-logo align-items-center justify-content-center">
        <img src="/iam/iam-hkd.png" alt="logo" style={{width: '100%', height: '100%'}}/>
      </Link>

      <button ref={menubuttonRef} type="button" className="p-link layout-menu-button layout-topbar-button" onClick={onMenuToggle}>
        <i className="pi pi-bars"/>
      </button>

      <button ref={topbarmenubuttonRef} type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={showProfileSidebar}>
        <i className="pi pi-ellipsis-v"/>
      </button>

      <div ref={topbarmenuRef} className={classNames('layout-topbar-menu', {'layout-topbar-menu-mobile-active': layoutState.profileSidebarVisible})}>
        {['Administrator', 'Agent'].includes(me.roleTypeId) && <li>
					<div className="house-holds">{me.partyName} [{me.username}]</div>
				</li>}
        {['CusManager'].includes(me.roleTypeId) && <li>
					<Households household={household} setHousehold={setHousehold}/>
				</li>}

        <div className="topbar-profile">
          <StyleClass nodeRef={accountRef} selector="@next" enterClassName="hidden" enterActiveClassName="scalein" leaveToClassName="hidden" leaveActiveClassName="fadeout" hideOnOutsideClick>
            <button ref={accountRef} className="p-link layout-topbar-button w-11rem" type="button">
              <i className="pi pi-user mr-2"/>
              <strong>{AppStorage.get('partyTaxId')}</strong>
            </button>
          </StyleClass>
          <ul className="iam-topbar-menu list-none p-3 m-0 border-round shadow-2 hidden absolute surface-overlay origin-top w-full md:w-16rem sm:w-12rem mt-2 right-0 top-auto">
            <li>
              <Link to="/quan-ly/thong-tin-tai-khoan" className="p-ripple flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer">
                <i className="pi pi-id-card mr-3"></i>
                <span>Thông tin</span>
              </Link>
              <div className="p-ripple flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer"
                   onClick={changePassword}>
                <i className="pi pi-key mr-3"></i>
                <span>Đổi mật khẩu</span>
              </div>
              <hr style={{margin: '0.25rem 0'}}/>
              <div className="p-ripple flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer"
                   onClick={logout}>
                <i className="pi pi-power-off mr-3"></i>
                <span>Đăng xuất</span>
              </div>
              <hr style={{margin: '0.25rem 0'}}/>
              <span className="inline-flex align-items-center" style={{textAlign: 'center', padding: '5px'}}>{me.partyName}</span>
            </li>
          </ul>
        </div>
      </div>

      {renderDialogChangePassword()}
    </div>
  );
});