import {ConfirmDialogService, FormatDisplay, ToastService, useDataTable, useDataTableBasic, useDialogCrup, useForm, useToolbar} from '@iamsoftware/react-hooks';
import React, {Fragment, useEffect, useState} from 'react';
import {Button} from 'primereact/button'
import {InvoiceServices as Service} from './InvoiceServices';
import { AutoComplete } from "primereact/autocomplete";
import {CommonService} from '../../../service/CommonService';
import {Fieldset} from 'primereact/fieldset';
import {SalesOrdersService} from '../don-hang/ordersService';
import {BussinessJobService} from '../../danh-muc/business-jobs/BussinessJobService';
import {InputNumber} from 'primereact/inputnumber';
import {Checkbox} from 'primereact/checkbox';
import {Dialog} from 'primereact/dialog';

interface Invoices {
  placedDate: string;
  displayId: string;
  customerPartyName: string;
  grandTotal: number;
  orderId: string;
}

export const SaleInvoices = (reloadOrder?) =>{
  const header = 'Hóa đơn';
  const dataKey = 'invoiceId';

  const [disabled, setDisabled] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null)
  const [invoiceFormTypes, setInvoiceFormTypes] = useState(null)
  const [issueStatusIds, setIssueStatusIds] = useState(null)
  const [paymentMethodEnumIds, setPaymentMethodEnumIds] = useState(null)
  const [items, setItems] = useState(null)
  const [treeCategories, setTreeCategories] = useState(null)
  const [grandTotal, setGrandTotal] = useState(0)
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalExemptAmount, setTotalExemptAmount] = useState(0);
  const [display,setDisplay] = useState(null)
  const [submitting,setSubmitting] = useState(false)
  const [invoiceId,setInvoiceId] = useState(null)

  const [createFromGdt, setCreateFromGdt] = useState(false);

  //luu lai gia tri hien tai cua issueStatusId , invoiceType, invoiceSerial khi click checkbox create TCGP
  const [currentIssue, setCurrentIssue] = useState(null);
  const [currentInvoiceType, setCurrentCurrentInvoiceType] = useState(null);
  const [currentInvoiceSerial, setCurrentInvoiceSerial] = useState(null);

  //thong tin kenh ban hang
  const [expenseItems, setExpenseItems] = useState(null);
  const [expenseTotal, setExpenseTotal] = useState(0);

  //xuất hóa đơn từ hệ thống TCGP
  const [createInvoiceToIntegParty, setCreateInvoiceToIntegParty] = useState(false)
  const [invoiceProviders, setInvoiceProviders] = useState(null);
  const [allowModifyInvoice, setAllowModifyInvoice] = useState(true);
  const [dirtyCheck, setDirtyCheck] = useState(false);

  //Hóa đơn đã được kết nối TCGP
  const [invoiceHadToIntegParty, setInvoiceHadToIntegParty] = useState(false);

  //UPdate by order screen
  const [updateByOrderScreen, setUpdateByOrderScreen] = useState(false);

  useEffect(() => {
    Service.orderAddbled().then(({items})=>{
      setInvoices(items.map(item =>{
        return {...item, value: item.orderId}
      }))
    })
    CommonService.getInvoiceFormTypesSales().then(({listData})=>{
      setInvoiceFormTypes(listData.map(item =>{
        return {value: item.enumId, label: `${item.enumCode} - ${item.description}`}
      }))
    })
    CommonService.getInvoiceIssueStatus().then(({listData})=>{
      setIssueStatusIds(listData.map(item  =>{
        return {value: item.statusId, label:item.description}
      }))
    });
    CommonService.getInvoicePaymentMethod().then(({listData})=>{
      setPaymentMethodEnumIds(listData.map(item =>{
        return {value: item.enumId, label: item.description}
      }))
    });
    BussinessJobService.getTreeList().then(({data})=>{
      setTreeCategories(data)
    })
    CommonService.getInvoiceProvider().then(({items}) =>{
      setInvoiceProviders(items.map(item =>{
        return {value: item.fromPartyId, label: item.fromPartyName}
      }))
    });
  }, []);

  const invoiceTemplate = (item: Invoices) => {
    return (
      <div className="flex">
        <div style={{margin:'5px', width:'6rem'}}>{FormatDisplay.date(item.placedDate,'YYYY-MM-DD')}</div>
        <div style={{margin:'5px' , width:'8rem', overflow:'hidden', textOverflow:'ellipsis',whiteSpace:'nowrap'}}>{item.displayId}</div>
        <div style={{margin:'5px', width:'22rem', overflow:'hidden', textOverflow:'ellipsis',whiteSpace:'nowrap'}}>{item.customerPartyName}</div>
        <div style={{margin:'5px'}}>{FormatDisplay.number(item.grandTotal)}</div>
      </div>
    );
  };

  const headerTable = (
    <div>
      <div className="flex justify-content-between align-items-center">
        <span>{header}</span>
        <Button icon="pi pi-plus" size="small" severity="success" className="mr-4 mb-1 h-2rem" onClick={() => doCreate()} label="Thêm"></Button>
      </div>
    </div>
  )

  const {render: renderDataTable, reloadLazyData} = useDataTable({
    tableHeader: headerTable,
    stateKey:'mainTable',
    dataKey,
    columns:[
      {field:'invoiceDate',header:'Ngày hóa đơn' ,matchMode:'contains', minWidth: 150, dataType:'date'},
      {field:'invoiceForm',header: 'Mẫu số' ,matchMode:'contains', minWidth: 150, dataType:'custom', customCell(rowData: any): any {
        return <span>{rowData.invoiceForm}-{rowData.invoiceFormTypeEnum}</span>
        }},
      {field:'invoiceSerial',header: 'Ký hiệu' ,matchMode:'contains', minWidth: 150},
      {field:'invoiceNo',header: 'Số hóa đơn' ,matchMode:'contains', minWidth: 150},
      {field:'invoiceProviderPseudoId',header: 'MST TCGP liên kết' ,matchMode:'contains', minWidth: 175},
      {field:'customer',header: 'Khách hàng' ,matchMode:'contains', minWidth: 250, dataType:'custom', customCell(rowData: any): any {
        return <span>[{rowData.toPseudoId}] - {rowData.toPartyName}</span>
        }},
      {field:'invoiceTotal',header: 'Giá trị HĐ' ,matchMode:'contains', minWidth: 150, dataType:'number'},
      {field:'issueStatus',header: 'TT phát hành' ,matchMode:'contains', minWidth: 150},
      {field:'externalId',header: 'Mã CQT' ,matchMode:'contains', minWidth: 250},
    ],
    indexColumnWidth:45,
    getList(lazyLoadEvent: any): Promise<any> {
      return Service.getList(lazyLoadEvent)
    },
    actionColumnWidth:90,
    initActions(items: Array<any>) {
      if (items){
        items.forEach(item =>{
          item.actions = [
            // {icon: 'pi pi-eye', title: 'Xem', className: 'p-button-info', command: () => doUpdate(item.invoiceId, true)},
            {icon: 'pi pi-pencil', title: 'Chỉnh sửa', className: 'p-button-warning', command: () => doUpdate(item.invoiceId, false, false)}
          ]
        })
      }
    }
  })

  const searchInvoices = () => {
    Service.orderAddbled().then(({items})=>{
      setInvoices(items.map(item =>{
        return {...item, value: item.orderId}
      }))
    })
  }

  //Set orderId và set Dirty = true
  const changeOrderId = (data) =>{
    setSelectedItem(data)
    setDirty(true)
  }

  const setCreateInvoiceDirtyChange = (checked: any) =>{
    setCreateInvoiceToIntegParty(checked);
    setDirtyCheck(true)
  }

  const commonInfoForm = useForm({
    fields:[
      {
        field: 'orderId', header: 'Đơn hàng', type: 'custom', className: 'md:col-3',
        body: <div>
          <label>Đơn hàng <span style={{color: 'red'}}>*</span></label>
          <AutoComplete className="mt-2" field="displayId" value={selectedItem} suggestions={invoices} disabled={disabled || updateByOrderScreen} forceSelection={true}
                        completeMethod={searchInvoices} onChange={(e) => changeOrderId(e.value)} itemTemplate={invoiceTemplate} dropdown/>
        </div>},
      {
        field:'customerPseudoId', header:'Mã khách hàng', className:'md:col-3', disabled: true
      },
      {
        field:'customerPartyName', header:'Tên khách hàng', className:'md:col-6', disabled: true
      },
      {
        field:'toPartyTaxId', header:'Mã số thuế',className:'md:col-3'
      },
      {
        field:'address1', header:'Địa chỉ', className:'md:col-9', disabled: true
      },
      {
        field:'paymentMethodEnumId', header:'Hình thức thanh toán', className:'md:col-3', type:'Dropdown', DropdownProps:{options: paymentMethodEnumIds}
      },
      {
        field:'sourceAccountNumber', header:'Số tài khoản', className:'md:col-4', disabledDependency(item: any): boolean {
          return item.paymentMethodEnumId !== 'PiWireTransfer'
        }
      },
      {
        field:'sourceBankName', header:'Tên ngân hàng', className:'md:col-5', disabledDependency(item: any): boolean {
          return item.paymentMethodEnumId !== 'PiWireTransfer'
        }
      },
      {
        field:'description', header:'Diễn giải', className:'md:col-12'
      },
      {
        field:'orderComments', header:'Ghi chú', className:'md:col-12'
      }
    ],
    readOnly: disabled
  })

  const certInfoForm = useForm({
    fields:[
      {
        type: 'custom', className:'md:col-12', display: !createFromGdt
        , body: <div className="flex align-items-center">
          <Checkbox inputId="invoiceProvider" disabled={!allowModifyInvoice} onChange={e => setCreateInvoiceDirtyChange(e.checked)} checked={createInvoiceToIntegParty}/>
          <label htmlFor="invoiceProvider" className="ml-2"><strong>Xuất hóa đơn trên hệ thống TCGP</strong></label>
        </div>
      },
      {field:'invoiceProviderPartyId', required: true, className:'md:col-12', header:'TCGP', type:'Dropdown', DropdownProps:{options: invoiceProviders}, display: createInvoiceToIntegParty === true},
      {field:'invoiceFormTypeEnumId', header:'Mẫu số',required: true, className:'md:col-12', type:'Dropdown', DropdownProps:{options: invoiceFormTypes}},
      {field:'invoiceSerial', header:'Ký hiệu',required: true, className:'md:col-6',disabled: createInvoiceToIntegParty},
      {field:'invoiceNo', header:'Số hóa đơn',required: true ? !createInvoiceToIntegParty : false, className:'md:col-6',disabled: createInvoiceToIntegParty},
      {field:'invoiceDate', header:'Ngày hóa đơn',required: true, className:'md:col-12', InputTextProps:{type:'date'}},
      {field:'issueStatusId', header:'Trạng thái phát hành',required: true, className:'md:col-12', type:'Dropdown', DropdownProps:{options: issueStatusIds},display: !createInvoiceToIntegParty},
      {field:'issueStatus', header:'TT phát hành', className:'md:col-12', disabled: true, display: createInvoiceToIntegParty},
      {field:'externalId', header:'Mã CQT', className:'md:col-12',disabled: createInvoiceToIntegParty}
    ],
    readOnly: disabled
  })

  const {invoiceProviderPartyId, invoiceFormTypeEnumId} = certInfoForm.getRawValue()
  //set lai dropdown mau hoa don khi thay doi checkbox xuat hoa don TCGP
  useEffect(() => {
    if (createInvoiceToIntegParty === true && invoiceProviderPartyId){
      if (certInfoForm.dirty['invoiceProviderPartyId']){
        const _value = certInfoForm.getRawValue();
        _value.issueStatus = 'Chưa xử lý';
        certInfoForm.setValue(_value);
      }

      CommonService.getInvoiceTemplateParty(invoiceProviderPartyId).then(({items}) =>{
        setInvoiceFormTypes(items.map(item =>{
          return {value: `${item.invoiceTemplatePartyId}_${item.invoiceFormTypeEnumId}_${item.invoiceSerial}`, label: `${item.invoiceForm} - ${item.invoiceSerial}`}
        }))
      })
    }else if (createInvoiceToIntegParty === false){
      CommonService.getInvoiceFormTypesSales().then(data => {
        setInvoiceFormTypes(data.listData.map(item => {
          return {value: item.enumId, label: `${item.enumCode} - ${item.description}`}
        }));
      });
    }
    if (dirtyCheck){
      switch (createInvoiceToIntegParty){
        case true:
          const _valueCert = certInfoForm.getRawValue();
          _valueCert.issueStatusId = 'InvIntegNotCreated';
          _valueCert.issueStatus = 'Chưa xử lý';
          _valueCert.invoiceNo = '';
          certInfoForm.setValue(_valueCert);
          break;
        case false:
          const _valueCert1 = certInfoForm.getRawValue();
          _valueCert1.issueStatusId = currentIssue;
          _valueCert1.invoiceFormTypeEnumId = currentInvoiceType;
          _valueCert1.invoiceSerial = currentInvoiceSerial;
          certInfoForm.setValue(_valueCert1);
          break;
      }
    }
  }, [createInvoiceToIntegParty, invoiceProviderPartyId]);

  //fill Ki hieu khi chon mau hoa don
  useEffect(() => {
    if (invoiceFormTypeEnumId && createInvoiceToIntegParty && certInfoForm.dirty['invoiceFormTypeEnumId']){
      const _value = certInfoForm.getRawValue();
      _value.invoiceSerial = invoiceFormTypeEnumId.split('_')[2];
      _value.invoiceNo = '';
      certInfoForm.setValue(_value)
    }
  }, [invoiceFormTypeEnumId]);

  const {render: renderDataTableBasic, setSelectedItems} = useDataTableBasic({
    tableHeader:'Danh sách sản phẩm',
    dataKey: '_id',
    indexColumnWidth:45,
    columns:[
      {field: 'productName', header: 'Sản phẩm', width: 150},
      {field: 'productCategory', header: 'Ngành nghề KD', width: 300},
      {field: 'quantityUom', header: 'ĐVT', width: 75},
      {field: 'quantity', header: 'SL', width: 75, dataType: 'number'},
      {field: 'unitListPrice', header: 'Đơn giá', width: 100, dataType: 'number'},
      {field: 'itemTotal', header: 'Thành tiền', width: 100, dataType: 'number'},
      {field: 'sourcePercentage', header: 'Tỷ lệ CK', width: 75, dataType: 'number'},
      {field: 'exemptAmount', header: 'Số tiền CK', width: 100, dataType: 'number'},
    ],
    items
  })

  const {render: renderExpenseDataTable} = useDataTableBasic({
    tableHeader: 'Thông tin chi phí',
    dataKey: 'orderItemSeqId',
    columns: [
      {field: 'itemDescription', header: 'Loại chi phí', minWidth: 200},
      {
        field: '', header: 'Áp dụng cho', dataType: 'custom', customCell(rowData: any): any {
          return <span>[{rowData.otherPartyProductId}] {rowData.comments}</span>
        }
      },
      {field: 'unitAmount', header: 'Số tiền', width: 150, dataType: 'number'},
    ]
    ,
    items: expenseItems
  })


  //Ham search tree category
  function searchTree(element, matchingKey) {
    if (element.key === matchingKey) {
      return element;
    } else if (element.children !== null) {
      var i;
      var result = null;
      for (i = 0; result === null && i < element.children?.length; i++) {
        result = searchTree(element.children[i], matchingKey);
      }
      return result;
    }
    return null;
  }

  //Fill dữ liệu khi thay đổi đơn hàng
  useEffect(() => {
    if (selectedItem?.orderId && dirty) {
      setItems(null)
      const {paymentMethodEnumId} = commonInfoForm.getRawValue()
      const {invoiceDate} = certInfoForm.getRawValue()
      SalesOrdersService.get(selectedItem.orderId).then(data => {
        setItems(data.items.map(it => {
          var category = null
          for (var i = 0; category === null && i < treeCategories.length - 1; i++) {
            category = searchTree(treeCategories[i], it.productCategoryId)
          }
          return {...it, itemTotal: it.quantity * it.unitListPrice, productCategory: category?.data?.categoryName, _id: `${it.productId}_${it.orderItemSeqId}_${Math.random()}`}
        }))
        data.paymentMethodEnumId = paymentMethodEnumId;
        data.invoiceDate = invoiceDate;
        if (data.expenseItems) {
          setExpenseItems(data.expenseItems)
        }
        if (data.expenseTotal) {
          setExpenseTotal(data.expenseTotal)
        }
        commonInfoForm.setValue(data);
        certInfoForm.setValue(data);
      })
    }
  }, [selectedItem]);

  //tinh tong tien invoice
  useEffect(() => {
    if (items && dirty) {
      setTotalAmount(0);
      setTotalExemptAmount(0);
      setGrandTotal(0)
      let totalCounter = 0;
      let exemptCounter = 0;
      let grandTotalCounter = 0;
      items.forEach(item => {
        if (item.productId) {
          totalCounter += item.itemTotal
          if (item.exemptAmount) {
            exemptCounter += item.exemptAmount
          }
        }
      });
      grandTotalCounter = totalCounter - exemptCounter + expenseTotal
      setTotalAmount(totalCounter);
      setTotalExemptAmount(exemptCounter);
        setGrandTotal(grandTotalCounter);
      }
  }, [JSON.stringify(items)]);

  const commonData = commonInfoForm.getRawValue()
  const certData = certInfoForm.getRawValue()

  const inject = () =>{
    const item = Object.assign(
      commonData,
      certData
    )
    //xuat hoa don TCGP
    if (createInvoiceToIntegParty){
      item.createInvoiceToIntegParty = 'Y'
      item.invoiceFormTypeEnumId = invoiceFormTypeEnumId.split('_')[1];
      item.invoiceTemplatePartyId = invoiceFormTypeEnumId.split('_')[0];
    }else {
      item.createInvoiceToIntegParty = 'N'
    }
    item.invoiceDate = new Date(item.invoiceDate).getTime()
    item.orderId = selectedItem.orderId
    return item
  }

  const doCreate = ()=>{
    setCreateFromGdt(false)
    setDirtyCheck(false);
    setCurrentInvoiceSerial(null);
    setCurrentIssue(null);
    setCurrentCurrentInvoiceType(null)
    setAllowModifyInvoice(true)
    setInvoiceId(null)
    setCreateInvoiceToIntegParty(false)
    setInvoiceHadToIntegParty(false)
    setSelectedItems([])
    setExpenseItems(null)
    setExpenseTotal(0)
    setGrandTotal(0);
    setTotalExemptAmount(0);
    setTotalAmount(0);
    setDisabled(false)
    setDirty(false)
    setItems(null)
    setSelectedItem(null)
    commonInfoForm.setValue({paymentMethodEnumId: 'PiWireTransfer'})
    certInfoForm.setValue({invoiceDate: FormatDisplay.date(new Date(), 'YYYY-MM-DD'), issueStatusId: issueStatusIds[0]?.value})
    setDisplay('Tạo mới ')
  }

  const doUpdate = (invoiceId, doView: boolean, updateByOrderScreen?: boolean) =>{
    setSelectedItems([])
    setExpenseTotal(0)
    setDirty(false)
    setUpdateByOrderScreen(updateByOrderScreen)
    if (invoiceId){
      setInvoiceId(invoiceId);
      Service.get(invoiceId).then(data =>{
        data.invoiceDate = FormatDisplay.date(new Date(data.invoiceDate),'YYYY-MM-DD');
        data.customerPartyName = data.toPartyName;
        data.customerPseudoId = data.toPseudoId;
        setItems(data.items.map(item=>{
          item._id = `${item.productId}_${item.invoiceItemSeqId}_${Math.random()}`
          item.sourcePercentage = item.adjustmentPercent;
          item.exemptAmount = item.adjustmentAmount;
          item.unitListPrice = item.listPrice;
          item.productName = item.description;
          item.productCategory = item.categoryName;
          return item
        }))
        //Xu ly gan expenseItems
        if (data.expenseItems) {
          setExpenseItems(data.expenseItems)
        }
        if (data.expenseTotal) {
          setExpenseTotal(data.expenseTotal)
        }
        //Gan tao hoa don tcgp
        if (data.createInvoiceToIntegParty === 'Y'){
          setCreateInvoiceToIntegParty(true)
          setInvoiceHadToIntegParty(true)
          if (!data.issueStatus){
            data.issueStatus = 'Chờ xử lý';
          }
          data.invoiceFormTypeEnumId = `${data.invoiceTemplatePartyId}_${data.invoiceFormTypeEnumId}_${data.invoiceSerial}`
        }else {
          setCreateInvoiceToIntegParty(false)
          setInvoiceHadToIntegParty(false)
        }
        if (data.allowModifyInvoice === 'N'){
          setAllowModifyInvoice(false)
        }else {
          setAllowModifyInvoice(true)
        }
        if (data.createFromGdt === 'Y'){
          setCreateFromGdt(true)
        }else {
          setCreateFromGdt(false)
        }
        //luu gia tri hien tai
        setCurrentCurrentInvoiceType(data.invoiceFormTypeEnumId)
        setCurrentIssue(data.issueStatusId)
        setCurrentInvoiceSerial(data.invoiceSerial)

        setGrandTotal(data.invoiceTotal);
        setTotalExemptAmount(data.invoiceDiscountTotal);
        setTotalAmount(data.invoiceSubTotal);
        setSelectedItem({orderId: data.orderId, displayId: data.orderDisplayId})
        certInfoForm.setValue(data);
        commonInfoForm.setValue(data)
        if (doView === true){
          setDisabled(true)
          setDisplay('Chi tiết ')
        }else {
          setDisabled(false)
          setDisplay('Cập nhật ')
        }
      })
    }
  }

  const onSubmit = event =>{
    if (!submitting && certInfoForm.valid() && commonInfoForm.valid()){
      setSubmitting(true);
      let promise;
      if (!selectedItem?.orderId){
        if (!selectedItem?.orderId) {
          ToastService.error('Đơn hàng không được rỗng')
        }
        setSubmitting(false);
      }else {
        if (invoiceId){
          promise = Service.update(invoiceId, inject())
        }else {
          promise = Service.create(inject());
        }
        promise.then(response => {
          if (response.errors) {
            ToastService.error(response.errors)
          } else {
            setDisplay(null);
            ToastService.success();
            reloadLazyData();
          }
        }).finally(() => {
          setSubmitting(false);
        });
      }
    }
    event.preventDefault();
  }

  const onSaveAndCreateTCGP = event =>{
    if (!submitting && certInfoForm.valid() && commonInfoForm.valid() && invoiceId){
      setSubmitting(true);
      let promise;
      if (!selectedItem?.orderId){
        if (!selectedItem?.orderId) {
          ToastService.error('Đơn hàng không được rỗng')
        }
        setSubmitting(false);
      }else {
        promise = Service.saveAndCreateTCGP(invoiceId,inject())
        promise.then(response => {
          if (response.errors) {
            ToastService.error(response.errors)
          } else {
            setDisplay(null);
            ToastService.success();
            reloadLazyData();
          }
        }).finally(() => {
          setSubmitting(false);
        });
      }
    }
    event.preventDefault();
  }

  const cancelConnect = ()=>{
    if (invoiceId){
      ConfirmDialogService.confirm('Ngắt kết nối','Bạn xác nhận muốn hủy liên kêt TCGP trên hóa đơn này', ()=>{
        Service.cancelTCGP(invoiceId).then(()=>{
          setDisplay(null);
          ToastService.success();
          reloadLazyData();
        })
      })
    }
  }

  const previewPdf = () =>{
    if (invoiceId){
      window.open(`${Service.baseURL}/${Service.entity}/${invoiceId}/tcgp-pdf`)
    }
  }

  const onSync = () =>{
    if (invoiceId){
      ConfirmDialogService.confirm('Đồng bộ','Bạn xác nhận đồng bộ từ TCGP trên hóa đơn này', ()=>{
        Service.syncTCGP(invoiceId).then((data)=>{
          const _value = certInfoForm.getRawValue();
          _value.invoiceDate = data.invoiceDate;
          _value.invoiceNo = data.invoiceNo;
          _value.issueStatus = data.issueStatus;
          _value.issueStatusId = data.issueStatusId;
          ToastService.success();
        })
      })
    }
  }

  const releasedInvoice = () =>{
    if (invoiceId){
      Service.issueInfoTCGP(invoiceId).then(({data}) =>{
        window.location.href = `myinvoice:${data.loginKey},${data.idValue},InvoiceSales,${invoiceId}`;
      })
    }
  }

  const saveAndReleasedInvoice = event =>{
    if (!submitting && certInfoForm.valid() && commonInfoForm.valid() && invoiceId){
      setSubmitting(true);
      let promise;
      if (!selectedItem?.orderId){
        if (!selectedItem?.orderId) {
          ToastService.error('Đơn hàng không được rỗng')
        }
        setSubmitting(false);
      }else {
        promise = Service.saveAndCreateTCGP(invoiceId,inject())
        promise.then(response => {
          if (response.errors) {
            ToastService.error(response.errors)
          } else {
            const _certValue = certInfoForm.getRawValue()
            _certValue.issueStatusId = response.issueStatusId
            _certValue.issueStatus = response.issueStatus
            certInfoForm.setValue(_certValue)
            Service.issueInfoTCGP(response?.invoiceId).then(data =>{
              window.location.href = `myinvoice:${data.loginKey},${data.idValue},InvoiceSales,${invoiceId}`;
            })
          }
        }).finally(() => {
          setSubmitting(false);
        });
      }
    }
    event.preventDefault();
  }

  const {issueStatusId} = certInfoForm.getRawValue()

  //tao invoice bang man hinh order
  const createByOrder = () =>{
    if (!selectedItem?.orderId) {
      ToastService.error('Đơn hàng không được rỗng')
    }else {
      SalesOrdersService.updateInvoice(selectedItem?.orderId, inject()).then(response => {
        reloadOrder();
        ToastService.success();
        setDisplay(null);
      })
    }
  }

  const footer = (viewByOrder?:any) =>{
    if (!invoiceId){
      return (
        <div className="flex justify-content-end">
          <Button icon="pi pi-check" label="Lưu" size="small" severity="info" className="mr-2" onClick={onSubmit} loading={submitting}></Button>
          <Button icon="pi pi-times" label="Đóng" size="small" severity="secondary" outlined onClick={() => setDisplay(null)}></Button>
        </div>
      )
    }else {
      if (!createInvoiceToIntegParty){
        return (
          <div className="flex justify-content-end">
            {!viewByOrder && <Button icon="pi pi-check" label="Lưu" size="small" severity="info" className="mr-2" onClick={onSubmit} loading={submitting}></Button>}
            {viewByOrder && <Button icon="pi pi-check" label="Lưu" size="small" severity="info" className="mr-2" onClick={createByOrder}></Button>}
            <Button icon="pi pi-times" label="Đóng" size="small" severity="secondary" outlined onClick={() => setDisplay(null)}></Button>
          </div>
        )
      }else{
        if (issueStatusId === 'InvIntegNotCreated'){
          return (
            <div className="flex justify-content-end">
              {invoiceHadToIntegParty && <Button icon="pi icons8-disconnected" label="Hủy liên kết TCGP" size="small" severity="danger" className="mr-2" onClick={() => cancelConnect()}></Button>}
              <Button icon="pi pi-plus" label="Lưu và phát hành hóa đơn" size="small" severity="help" className="mr-2" onClick={saveAndReleasedInvoice} loading={submitting}></Button>
              <Button icon="pi pi-plus" label="Lưu và tạo trên HT TCGP" size="small" severity="success" className="mr-2" onClick={onSaveAndCreateTCGP} loading={submitting}></Button>
              <Button icon="pi pi-check" label="Lưu" size="small" severity="info" className="mr-2" onClick={onSubmit} loading={submitting}></Button>
              <Button icon="pi pi-times" label="Đóng" size="small" severity="secondary" outlined onClick={() => setDisplay(null)}></Button>
            </div>
          )
        }else if (issueStatusId === 'InvIntegDataInvalid'){
          return (
            <div className="flex justify-content-end">
              {invoiceHadToIntegParty && <Button icon="pi icons8-disconnected" label="Hủy liên kết TCGP" size="small" severity="danger" className="mr-2" onClick={() => cancelConnect()}></Button>}
              <Button icon="pi pi-file-pdf" label="Xem PDF" size="small" severity="success" className="mr-2" onClick={() => previewPdf()}></Button>
              <Button icon="pi pi-sync" label="Đồng bộ" size="small" severity="info" className="mr-2" onClick={() => onSync()}></Button>
              <Button icon="pi pi-times" label="Đóng" size="small" severity="secondary" outlined onClick={() => setDisplay(null)}></Button>
            </div>
          )
        }else if (issueStatusId === 'InvIntegDeleted'){
          return (
            <div className="flex justify-content-end">
              {invoiceHadToIntegParty && <Button icon="pi icons8-disconnected" label="Hủy liên kết TCGP" size="small" severity="danger" className="mr-2" onClick={() => cancelConnect()}></Button>}
              <Button icon="pi pi-times" label="Đóng" size="small" severity="secondary" outlined onClick={() => setDisplay(null)}></Button>
            </div>
          )
        }else {
          return (
            <div className="flex justify-content-end">
              <Button icon="pi pi-file-edit" label="Phát hành hóa đơn" size="small" severity="help" className="mr-2" onClick={() => releasedInvoice()}></Button>
              <Button icon="pi pi-file-pdf" label="Xem PDF" size="small" severity="success" className="mr-2" onClick={() => previewPdf()}></Button>
              <Button icon="pi pi-sync" label="Đồng bộ" size="small" severity="info" className="mr-2" onClick={() => onSync()}></Button>
              <Button icon="pi pi-times" label="Đóng" size="small" severity="secondary" outlined onClick={() => setDisplay(null)}></Button>
            </div>
          )
        }
      }
    }
  }

  const renderSaleInvoice = (viewByOrder?: boolean) => {
    return (
        <div className="grid">
          {(!viewByOrder) &&<div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 12.5rem)'}}>
            {renderDataTable()}
          </div>}
          <Dialog header={display + header} footer={footer(viewByOrder)} visible={!!display} maximized={true} draggable={false}
                  onHide={() => setDisplay(null)}>
            <div className="grid">
              <div className="col-12 md:col-9">
                <Fieldset legend="Thông tin chung">
                  {commonInfoForm.render()}
                </Fieldset>
              </div>
              <div className="col-12 md:col-3 fieldset-width-invoice">
                <Fieldset legend="Chứng từ">
                  {certInfoForm.render()}
                </Fieldset>
              </div>
              <div className="col-12 md:col-12">
                <div>
                  <div style={{maxHeight: 'calc(100vh - 15.5rem)'}}>
                    {renderDataTableBasic()}
                  </div>
                  {expenseItems?.length > 0 && <div className="flex justify-content-between mt-3">
                    <div className="flex" style={{width: '60vw', height: '200px'}}>
                      {renderExpenseDataTable()}
                    </div>
                    <div className="flex" style={{width: '30vw', flexDirection: 'column'}}>
                      <div className="flex align-items-center mb-3 ml-4">
                        <label htmlFor="total-amount" style={{fontSize: '1.1rem', width: '50%'}}
                               className="font-bold block">Tổng tiền hàng: </label>
                        <InputNumber inputId="total-amount" value={totalAmount} disabled/>
                      </div>
                      <div className="flex align-items-center mb-3 ml-4">
                        <label htmlFor="total-exempt" style={{fontSize: '1.1rem', width: '50%'}}
                               className="font-bold block">Tổng tiền chiết khấu: </label>
                        <InputNumber inputId="total-exempt" value={totalExemptAmount} disabled/>
                      </div>
                      <div className="flex align-items-center mb-3 ml-4">
                        <label htmlFor="total-expense" style={{fontSize: '1.1rem', width: '50%'}}
                               className="font-bold block">Tổng phí phải trả: </label>
                        <InputNumber inputId="total-expense" value={expenseTotal} disabled/>
                      </div>
                      <div className="flex align-items-center mb-3 ml-4">
                        <label htmlFor="total" style={{fontSize: '1.1rem', width: '50%'}} className="font-bold block">Tổng
                          tiền thanh toán: </label>
                        <InputNumber inputId="total" value={grandTotal} disabled/>
                      </div>
                    </div>
                  </div>}
                  {(!expenseItems || expenseItems.length === 0) &&
                      <div className="flex flex-row-reverse flex-wrap mt-3">
                        <div className="ml-4">
                          <label htmlFor="total" className="font-bold block mb-2">Tổng tiền thanh toán</label>
                          <InputNumber inputId="total" value={grandTotal} disabled/>
                        </div>
                        <div className="ml-4">
                          <label htmlFor="total-exempt" className="font-bold block mb-2">Tổng tiền chiết khấu</label>
                          <InputNumber inputId="total-exempt" value={totalExemptAmount} disabled/>
                        </div>
                        <div>
                          <label htmlFor="total-amount" className="font-bold block mb-2">Tổng tiền hàng</label>
                          <InputNumber inputId="total-amount" value={totalAmount} disabled/>
                        </div>
                      </div>}
                </div>
              </div>
            </div>
          </Dialog>
        </div>
    )
  }

  return {renderSaleInvoice, doUpdateSaleInvoice: doUpdate, reloadSaleInvoice: reloadLazyData}
}